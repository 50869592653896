import {request} from "./network";
import store from "../store";

// 获取成绩比例列表
export function getStudentScoreInfo(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getStudentScoreInfo",
        data,
    });
}

// 获取成绩比例列表
export function getYjSubjectConf(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/auth/getYjSubjectConf",
        data,
    });
}