<template>
    <div class="base-container">
        <el-card class="box-card card_one">
            <div slot="header" class="clearfix">
                <span>成绩比例</span>
            </div>
            <div class="item">
                <div id="echartsId" style="width: 100%;height: 100%;"></div>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top: 20px;">
            <div slot="header" class="clearfix">
                <span>分数比例参照表</span>
            </div>
            <div class="item">
                <div class="table-box">
                    <table cellspacing="0">
                        <tr>
                            <th width="32%">限制的分数</th>
                            <th width="34%">限制的比例/数量</th>
                            <th width="34%">当前的比例/数量</th>
                        </tr>
                        <tbody v-if="tableList.length>0">
                        <template v-for="(item, index) in tableList">
                            <tr>
                                <td rowspan="2">{{item.score}}</td>
                                <td>{{item.subject_scale}}</td>
                                <td :class="{
                                    'is_red': item.now_nums>item.subject_nums
                                }">{{item.now_scale}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{item.subject_nums}}</td>
                                <td :class="{
                                    'is_red': item.now_nums>item.subject_nums
                                }">{{item.now_nums}}
                                </td>
                            </tr>
                        </template>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="3">暂无数据</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import {getStudentScoreInfo, getYjSubjectConf} from "../../api/scale";

    export default {
        name: "score",
        data() {
            return {
                dataList: [],
                tableList: [],
            }
        },
        created() {
            this.getList();
            this.getTableList();
        },
        methods: {
            getTableList() {
                getYjSubjectConf({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message)
                        this.tableList = res.data.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getList() {
                getStudentScoreInfo({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message)
                        // this.$message.success(res.data.message);
                        let dataObj = res.data.data.list;
                        let dataArr = [];
                        let xAxisData = [];
                        let yAxisData = [];
                        for (let key in dataObj) {
                            if (key != '0-100') {
                                let obj = {
                                    id: key,
                                    value: dataObj[key]['rate'],
                                    sum: dataObj[key]['sum']
                                }
                                dataArr.push(obj);
                            }
                        }
                        dataArr.sort((a, b) => Number(a['id'].split('-')[0]) - Number(b['id'].split('-')[0]));
                        dataArr.forEach(item => {
                            xAxisData.push(item)
                            yAxisData.push(item.id)
                        })
                        this.drawChart(xAxisData, yAxisData);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            drawChart(xAxisData, yAxisData) {
                // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
                let myChart = this.$echarts.init(document.getElementById("echartsId"));
                // 指定图表的配置项和数据
                let option = {
                    title: {},
                    xAxis: {
                        name: '%',
                        position: 'top',
                        type: 'value',
                        nameTextStyle: {
                            color: '#000',
                            fontSize: '18',
                            fontWeight: 'bold',
                        },
                        axisLabel: {
                            color: '#000',
                            fontSize: '18',
                        },
                        axisLine: {
                            show: true,
                        },
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        // data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                    },
                    yAxis: {
                        name: '成绩',
                        position: 'left',
                        nameLocation: 'start',
                        type: 'category',
                        nameTextStyle: {
                            color: '#000',
                            fontSize: '18',
                            fontWeight: 'bold',
                        },
                        axisLabel: {
                            color: '#000',
                            fontSize: '18',
                        },
                        axisLine: {
                            show: true,
                        },
                        data: yAxisData,
                    },
                    series: [
                        {
                            name: "比例",
                            type: "bar",
                            data: xAxisData,
                            label: {
                                // 柱图头部显示值
                                show: true,
                                position: "right",
                                color: "#000",
                                fontSize: "18",
                                formatter: (params) => {
                                    console.log(params)
                                    if (params.value) {
                                        return '(' + params.data['value'] + '%, ' + params.data['sum'] + ')';
                                    }
                                    return '';
                                },
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            color: '#000',
                            fontSize: '18',
                        },
                        formatter: function (params) {
                            let str = '';
                            let item = params[0];
                            str += item.name + "</br>" + item.marker + " 比例：" + item.data['value'] + "%" + "</br>" + item.marker + " 数量：" + item.data['sum'] + "</br>"
                            return str
                        }
                    }
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            },
        }
    }
</script>

<style scoped lang="scss">
    .base-container {
        width: 100%;
        height: 100%;

        ::v-deep .el-card {
            &.card_one {
                height: 100%;
            }

            width: 100%;
            display: flex;
            flex-flow: column;
            position: relative;
            /*padding-bottom: 72px;*/

            .el-card__header {
                /*height: 60px;*/
                font-size: 18px;
                font-weight: bold;
                line-height: 40px;
                padding: 10px 20px;
            }

            .el-card__body {
                flex: 1;
                display: flex;
                flex-flow: column;
                overflow-y: auto;

                .item {
                    height: 100%;

                    .empty-area {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0;
                    }

                    .el-pagination {
                        position: absolute;
                        height: 32px;
                        right: 20px;
                        bottom: 20px;
                    }

                    .table-box {
                        padding: 20px 20px 30px;

                        .table-box-tit {
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 30px;
                            margin: 10px 0;
                        }

                        table {
                            width: 100%;
                            border-left: 1px solid #000000;
                            border-top: 1px solid #000000;

                            th {
                                font-weight: bold;
                            }

                            td, th {
                                padding: 15px;
                                font-size: 18px;
                                text-align: center;
                                border-right: 1px solid #000000;
                                border-bottom: 1px solid #000000;

                                &.is_red {
                                    color: red;
                                }
                            }
                        }
                    }
                }
            }
        }

        ::v-deep .img-dialog {
            .el-dialog__header,
            .el-dialog__body {
                padding: 0;
            }

            .dialog-header {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                padding: 12px 56px 12px 20px;
                border-bottom: 1px solid #EBEEF5;

                .txt {
                    font-size: 14px;

                    span {
                        margin-right: 20px;
                    }
                }

                .py-btn {
                    color: #ffffff;
                    border-color: #409EFF;
                    background-color: #409EFF;

                }
            }

            .dialog-body {
                padding: 20px;
                height: 440px;

                .el-image {
                    width: 100%;
                    height: 100%;

                    > img {
                        max-width: 100% !important;
                        max-height: 100% !important;
                    }
                }
            }
        }
    }

    ::v-deep .table-area {
        width: 100%;
        font-size: 13px;

        .cell {
            line-height: normal;
        }

        .el-table__header {
            th {
                padding: 15px 0;
                color: #333333;
                font-weight: bold;
                background-color: #F0F2F5;
                border-top: 1px solid #EBEEF5;

                &:first-child {
                    border-left: 1px solid #EBEEF5;
                    border-radius: 4px 0 0 4px;
                }

                &:nth-last-child(2) {
                    border-right: 2px solid #EBEEF5;
                    border-radius: 0 4px 4px 0;
                }


            }
        }

        .el-table__body {
            td {
                padding: 10px 0;
            }

            .pic {
                width: 30px;
                height: 30px;
                cursor: pointer;
                vertical-align: top;
                border: 1px solid #EBEEF5;

                > img {
                    max-width: 100% !important;
                    max-height: 100% !important;
                }
            }

            .el-button {
                padding: 9px;

                &:hover {
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
    }
</style>
